var W = $(window),
	D = $(document);
var GMAPSURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDyJh6d1_13v_udQMlvz7Zg46rVDrs8-JE';

// $('.select-trigger').selectInput();
$('.textInput-phone').each(function(){
	var input = $(this);
		if (input.hasClass('required')) {
			input.mask("+7 999 999-99-99");
		} else {
			input.mask("+7 999 999-99-99");
		};
});
$('.radio').radio();
$('.checkbox').checkbox();


$('.tabs-trigger').tabs();


// $('.textInput-digits').digitsOnly();
$('.form-required').required();

// $('.rubl').text('₽');


(function(){	
	var mw = $('.mw'),
		mwInner = $('.mw-inner'),
		qnt = 0;

	W.on('content:fix',function(){
		if (!W.data('fixed')) {
			W.data('fixed',true);
			mwInner.css('margin-top',-D.scrollTop());
			$('html').addClass('content-fixed');
		};
		qnt++;
		W.data('qnt',qnt);
	}).on('content:unfix',function(){
		qnt--;
		if (qnt <= 0) {
			qnt = 0;
			var t = -parseInt(mwInner.css('margin-top'));
			mwInner.css('margin-top','');
			$('html').removeClass('content-fixed');
			D.scrollTop(t);
			W.data('fixed',false);
		};
		W.data('qnt',qnt);
	});
})();



$('.js-replace-svg').replaceSVG();






var popup = $('<div class="popup popup-hidden"><div class="popup-inner"><div class="popup-close" title="Закрыть"><span></span></div><div class="popup-content-box"></div></div></div>');
popup.each(function(){
	var isAnimated = false,
		close, inner, contentBox, spacer = $('<div class="popup-spacer"></div>'), cls, options = {};

	function init(e,cnt) {
		popup.appendTo('body');
		popup.off('show',init);
		popup.on('show',show);
		close = popup.find('.popup-close');
		contentBox = popup.find('.popup-content-box');
		inner = popup.find('.popup-inner');
		popup.trigger('show',[cnt]);
		popup.on('mousedown',hide);
		inner.on('mousedown',function(e){
			e.stopPropagation();
		});
		close.on('click',hide);
	};
	function show(e,opts) {
		if (typeof opts === 'string' && $('#'+opts)) {
			options.content = $('#'+opts);
		} else if (typeof opts === 'object') {
			options = opts;
		};
		if (!isAnimated && options.content) {
			isAnimated = true;
			W.trigger('content:fix');
			spacer.insertAfter(options.content);
			cls = options.content.data('class');

			contentBox.append(options.content);
			popup
				.addClass(cls)
				.css({opacity: 0})
				.removeClass('popup-hidden')
				.transit({ opacity: 1},200,function(){
					isAnimated = false;
				});
				popup.find('.textInput:eq(0)').focus();
				if (options.onOpen) options.onOpen();
			D.scrollTop(0);
			D.on('keyup.popup',function(e){
				if (e.which == 27) hide();
			});
		};
	};
	function hide(e) {
		if (!isAnimated) {
			isAnimated = true;
			popup
				.transit({ opacity: 0},200,function(){
					popup.addClass('popup-hidden');
					if (cls) popup.removeClass(cls);
					W.trigger('content:unfix');
					options.content.insertAfter(spacer);
					if (options.onClose) options.onClose();
					options = {};
					cls = null;
					isAnimated = false;
					// popup.trigger('hide.onclose').off('hide.onclose');
					contentBox.empty();
				});
			D.off('keyup.popup');
		};
	};
	popup.on('show',init);
	popup.on('hide',hide);

	D.on('click','.popup-trigger-show',function(){
		var c = $(this).data('content');
		if (c && $('#'+c).length) popup.trigger('show',[{
			content: $('#'+c),
			onOpen: function(){
				$('.popup:eq(0)').find('.textInput:eq(0)').focus();
			}
		}]);
		return false;
	});
});



$('.sidebar').each(function(){
	var sideBar = $('.sidebar').eq(0),
		burger = $('.header-burger').eq(0),
		sidebarBack = $('.sidebar-back').eq(0);
		

	burger.click(function(){
		if (!$('html').hasClass('sidebar-show')) {
			show();
		} else {
			hide();
		};
	});
	sidebarBack.click(hide);

	var timer;
	function show() {
		clearTimeout(timer);
		W.trigger('content:fix');
		sideBar.show();
		sidebarBack.show();
		setTimeout((function(){
			$('html').addClass('sidebar-show');
		}),50);
	};
	function hide() {
		W.trigger('content:unfix');
		$('html').removeClass('sidebar-show');
		timer = setTimeout((function(){
			sideBar.hide();
			sidebarBack.hide();
		}),200);
	};
	sideBar
		.on('show',show)
		.on('hide',hide);
});


setTimeout((function(){
	$('.qotd').each(function(){
		$(this).slideDown(200);
	})
}),400)
