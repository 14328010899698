$('.request-feedback').each(function(){
	var f = $(this);

	$('.js-feedback-trigger').on('click',function(){
		var heading = $(this).data('heading') || 'Связаться с нами';
		f.find('.popup-heading').text(heading);
		popup.trigger('show',[{
			content: f
		}]);
		return false;
	});
});