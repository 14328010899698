$('.about-map').each(function(){
	var am = $(this),
		city = '',
		rel = '',
		box = am.find('.am-wrapper'),
		cities = am.find('.am-map-item'),
		items = am.find('.cb-item'),
		sws = am.find('.cb-switcher-item');

	box.hide();

	var swiper = '<div class="cb-switcher-swiper"><div class="swiper-container"><div class="swiper-wrapper">';
	sws.each(function(){
		swiper += '<div class="swiper-slide">'+$(this).text()+'</div>';
	});
	swiper += '</div></div></div>'
	$('.cb-switcher-wrapper').after(swiper);

	swiper = new Swiper(am.find('.cb-switcher-swiper .swiper-container')[0],{
		onSlideChangeStart: function(swiper) {
			var c = swiper.activeIndex;
			sws.eq(c).trigger('click');
		}
	});

	am.find('.cb-switcher-item').on('click',function(){
		var a = $(this);
		if (!a.hasClass('active')) {
			am.find('.cb-switcher-item.active').removeClass('active');
			a.addClass('active');
			rel = a.data('rel');
			if (rel != 'all') {
				cities.hide();
				cities.filter('._'+rel).show();
				if (W.width() < 900) {
					items.hide();
					items.filter('.cb-item-'+rel).show();
				};
				history.replaceState(rel, document.title, window.location.pathname+'#'+rel);
				var c = sws.index(a);
				swiper.slideTo(c);
			} else {
				cities.show();
				if (W.width() < 900) {
					items.show();
				};
				history.replaceState('', document.title, window.location.pathname);
				swiper.slideTo(0);
			};
			box.hide();
			cities.removeClass('active');
		};
	});

	cities.on('click',function(){
		var c = $(this);
		if (!c.hasClass('active')) {
			cities.removeClass('active');
			c.addClass('active');
			city = c.data('rel');
			box
				.find('.cb-item').hide()
				.filter('.cb-item-city-'+city).show();
			box.show();
		};
	});
	var hash = window.location.hash.replace(/#/g,'');
	if (hash) {
		am.find('.cb-switcher-item[data-rel="'+hash+'"]').trigger('click');
	};
});