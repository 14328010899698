$('.contacts-info').each(function(){
	var ci = $(this),
		map, infoWindow, coords;

	$.getScript(GMAPSURL,function(){
		initMap();
	});

	function initMap() {
		setCoords();
		
		map = new google.maps.Map(document.getElementById('contacts-map'), {
			center: coords,
			zoom: 16,
			disableDefaultUI: true,
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.TOP_LEFT
			},
			scrollwheel: false
		});
		google.maps.event.addListener(map, 'click', function(event) {
			console.log(event.latLng.toString());
		});
		function resizeMap() {
			var center = map.getCenter();
			google.maps.event.trigger(map, "resize");
			map.setCenter(center);
		};
		google.maps.event.addDomListener(window, "resize", function() {
		    resizeMap();
		});
		W.bind('resize.map',resizeMap);

		infoWindow = new google.maps.InfoWindow({
			content: ci.find('.cm-address.active .cm-address-window-wrapper').html(),
			position: coords
		});
		infoWindow.open(map);
	};
	function setCoords() {
		coords = ci.find('.cm-address.active').data('coords').split(',');
		coords = {
			lat: coords[0]*1,
			lng: coords[1]*1
		};
	};

	ci.find('.cm-address').on('click',function(){
		var a = $(this);

		if (!a.hasClass('active')) {
			ci.find('.cm-address').removeClass('active');
			a.addClass('active');
			setCoords();
			if (map && infoWindow) {
				map.setCenter(coords);
				infoWindow.setOptions({
					content: ci.find('.cm-address.active .cm-address-window-wrapper').html(),
					position: coords
				});
			};
		};
	});
});


$('.js-contacts-branches').each(function(){
	var cb = $(this),
		items = cb.find('.cb-item'),
		sws = cb.find('.cb-switcher-item');

	var swiper = '<div class="cb-switcher-swiper"><div class="swiper-container"><div class="swiper-wrapper">';
	sws.each(function(){
		swiper += '<div class="swiper-slide">'+$(this).text()+'</div>';
	});
	swiper += '</div></div></div>'
	$('.cb-switcher-wrapper').after(swiper);

	swiper = new Swiper(cb.find('.cb-switcher-swiper .swiper-container')[0],{
		onSlideChangeStart: function(swiper) {
			var c = swiper.activeIndex;
			sws.eq(c).trigger('click');
		}
	});

	sws.on('click',function(){
		var a = $(this);
		if (!a.hasClass('active')) {
			cb.find('.cb-switcher-item.active').removeClass('active');
			a.addClass('active');
			var rel = a.data('rel');
			if (rel != 'all') {
				items.hide();
				items.filter('.cb-item-'+rel).show();
				history.replaceState(rel, document.title, window.location.pathname+'#'+rel);
				var c = sws.index(a);
				swiper.slideTo(c);
			} else {
				items.show();
				history.replaceState('', document.title, window.location.pathname);
				swiper.slideTo(0);
			};
		};
	});
	var hash = window.location.hash.replace(/#/g,'');
	if (hash) {
		cb.find('.cb-switcher-item[data-rel="'+hash+'"]').trigger('click');
	};
});