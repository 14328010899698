$('.top-slider').each(function(){
	var ts = $(this);

	function setEls() {
		var item = $(this),
			txt = item.text().split(' '),
			newText = '';

		for (var i = 0; i < txt.length; i++) {
			newText += '<span class="ts-item-showbox">'+txt[i]+'</span> ';
		};
		item.html(newText);
	}
	ts.find('.ts-item-inner').each(function(){
		var item = $(this);
		item.find('.ts-item-heading').each(setEls);
		item.find('.ts-item-descr').each(setEls);
		item.find('.ts-item-cta').addClass('ts-item-showbox');
		item.find('.ts-item-r').addClass('ts-item-showbox');
	});

	var swiper = new Swiper(ts.find('.swiper-container')[0],{
		loop: true,
		nextButton: ts.find('.ts-nav-next')[0],
		prevButton: ts.find('.ts-nav-prev')[0],
		pagination: ts.find('.swiper-pagination')[0],
		paginationClickable: true,
		autoplay: 8000,
		onSlideChangeEnd: function(swiper) {
			ts.find('.swiper-slide-active').each(function(){
				var slide = $(this);
				slide.find('.ts-item-showbox').each(function(index){
					$(this).css({
						opacity: 0,
						y: -20
					}).transit({
						opacity: 1,
						y: 0,
						delay: index*50
					},200)
				});
			});
			ts.find('.swiper-slide-prev,.swiper-slide-next').each(function(){
				var slide = $(this);
				slide.find('.ts-item-showbox').each(function(index){
					$(this).css({
						opacity: 0,
					});
				});
			});
		}
	});
});